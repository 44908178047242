import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow">
      <div className="container mx-auto flex justify-between items-center p-4">
        <Link to="/" className="text-2xl font-bold text-gray-900">
          Arkitech
        </Link>
        <nav>
          <Link to="/" className="text-blue-500 hover:underline mr-4">Home</Link>
          <Link to="/privacy-policy" className="text-blue-500 hover:underline mr-4">Privacy Policy</Link>
          <Link to="/terms-of-service" className="text-blue-500 hover:underline mr-4">Terms of Service</Link>
          <Link to="/contact-us" className="text-blue-500 hover:underline">Contact Us</Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
