import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
      <div className="text-gray-700 mt-8">
        <h2 className="text-2xl font-bold mb-2">Acceptance of Terms:</h2>
        <p className="mb-4">
          By using the App, you agree to comply with these Terms of Service.
        </p>

        <h2 className="text-2xl font-bold mb-2">1. User Responsibilities:</h2>
        <ul className="list-disc ml-8 mb-4">
          <li>You are responsible for ensuring that your use of the App complies with all applicable laws and regulations.</li>
          <li>You agree not to misuse the App or engage in any prohibited activities, including but not limited to spamming, hacking, or unauthorized access.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">2. Intellectual Property Rights:</h2>
        <ul className="list-disc ml-8 mb-4">
          <li>All content and intellectual property rights associated with the App are owned by us or our licensors.</li>
          <li>By using the App, you grant us a license to use any content you upload or create within the App for the purpose of providing and improving our services.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">3. Limitation of Liability:</h2>
        <ul className="list-disc ml-8 mb-4">
          <li>We are not liable for any damages resulting from your use of the App, including but not limited to loss of data or interruption of service.</li>
          <li>Our liability is limited to the maximum extent permitted by law.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">4. Governing Law:</h2>
        <p className="mb-4">
          These Terms of Service are governed by the laws of the State of Wyoming, United States, and any disputes will be resolved in the courts of Wyoming.
        </p>

        <h2 className="text-2xl font-bold mb-2">5. Changes to Terms of Service:</h2>
        <p className="mb-4">
          We may update these Terms of Service from time to time. Any changes will be effective immediately upon posting on this page.
        </p>

        <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:pluginsnocode1@gmail.com" className="text-blue-500 hover:underline">pluginsnocode1@gmail.com</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
