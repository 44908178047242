// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBej9FlLmPE2awZT9aizeT2gnAOk8n_3c8",
  authDomain: "neat-glazing-418413.firebaseapp.com",
  projectId: "neat-glazing-418413",
  storageBucket: "neat-glazing-418413.appspot.com",
  messagingSenderId: "767859187011",
  appId: "1:767859187011:web:bcef48e12a8d5b6e5ae3f6",
  measurementId: "G-CRP8SMMTVP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };