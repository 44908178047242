import React from 'react';

const Features = () => {
  return (
    <section id="features" className="bg-white py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-12">Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 border rounded-lg">
            <h3 className="text-2xl font-bold mb-4">Sync new files added to Drive folder to monday.com</h3>
            <p>Automatically synchronize new files from your Google Drive to your monday.com boards, ensuring everything stays up-to-date.</p>
          </div>
          <div className="p-6 border rounded-lg">
            <h3 className="text-2xl font-bold mb-4">Sync files from monday.com items to Google Drive folder</h3>
            <p>Ensure all your files are backed up and organized by syncing files from monday.com items to your specified Google Drive folder.</p>
          </div>
          <div className="p-6 border rounded-lg">
            <h3 className="text-2xl font-bold mb-4">Create new folder in Drive when new item is created</h3>
            <p>Keep your Google Drive organized by automatically creating new folders for every new item created in monday.com.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
