import React from 'react';
import logo from '../assets/logo.png';  // Make sure this path is correct

const Hero = () => {
  return (
    <section className="bg-gray-100 text-center py-20">
      <div className="container mx-auto">
        <img src={logo} alt="Google Drive 2-way Sync Logo" className="h-20 mx-auto mb-4" />  {/* Adjust size as needed */}
        <h1 className="text-5xl font-bold text-gray-900 mb-4">Google Drive 2-way Sync</h1>
        <p className="text-xl text-gray-700 mb-6">Seamlessly synchronize your files between Google Drive and monday.com</p>
        <a href="#features" className="bg-blue-500 text-white px-6 py-3 rounded-lg text-lg">Learn More</a>
      </div>
    </section>
  );
};

export default Hero;
