import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
      <div className="text-gray-700">
        <h2 className="text-2xl font-bold mb-2">Introduction:</h2>
        <p className="mb-4">
          Welcome to Google Drive 2-way Sync! This Privacy Policy describes how we collect, use, and share information when you use our monday.com integration app ("App").
        </p>

        <h2 className="text-2xl font-bold mb-2">1. Types of Information Collected:</h2>
        <h3 className="text-xl font-bold mb-1">User Data:</h3>
        <p className="mb-4">
          When you use our App, we may collect certain information from you, including your monday.com and Google Drive account information. Specifically, we access the following Google user data: <br/>

          <ul style={{'list-style-type' : 'disc', marginLeft: '20px', marginTop: '10px'}}>
            <li>Google Drive file metadata (e.g., file names, IDs, and types)</li>
            <li>Google Drive folder metadata (e.g., folder names and IDs)</li>
            <li>Basic profile information associated with your Google account (e.g., email address and user ID)</li>
          </ul>

        </p>
        <h3 className="text-xl font-bold mb-1">Usage Data:</h3>
        <p className="mb-4">
          We may also collect usage data related to how you interact with the App.
        </p>

        <h2 className="text-2xl font-bold mb-2">2. Use of Information:</h2>
        <p className="mb-4">
          We use the collected information to provide and maintain the App's functionality, including creating automations between monday.com and Google Drive as described in the App's features.
        </p>
        <p className="mb-4">
          We do not retain any files or content from Google Drive on our servers, except for data necessary for the app's functionality, such as the metadata of files within the Drive folder designated by you. All data processing is conducted on secure servers provided by monday.com.
        </p>
        <p className="mb-4">
          Any information collected is used solely for the purpose of providing and improving the App's services.
        </p>

        <h2 className="text-2xl font-bold mb-2">3. Data Security:</h2>
        <p className="mb-4">
          We take appropriate measures to protect the security of your information and prevent unauthorized access or disclosure.
        </p>

        <h2 className="text-2xl font-bold mb-2">4. Sharing, Transferring, or Disclosing Google User Data:</h2>
        <p className="mb-4">
          We do not share, transfer, or disclose your Google user data to third parties. All data access and processing are performed in compliance with applicable privacy laws and Google’s API Services User Data Policy. <br/>
          The app's use and transfer to any other app of information received from Google APIs will adhere to <a className='text-blue-500 hover:underline' target='_blank' rel="noreferrer" href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.
        </p>

        <h2 className="text-2xl font-bold mb-2">5. User Rights:</h2>
        <p className="mb-4">
          You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.
        </p>

        <h2 className="text-2xl font-bold mb-2">6. Updates to the Privacy Policy:</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and you will be notified of any significant updates.
        </p>

        <h2 className="text-2xl font-bold mb-2">Third-party Services</h2>
        <p className="mb-4">
          The app utilizes Google OAuth for authentication purposes. By using the app, you agree to Google's Privacy Policy.
        </p>

        <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:pluginsnocode1@gmail.com" className="text-blue-500 hover:underline">pluginsnocode1@gmail.com</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
